/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Welcome } from '../../iconComponents';
import Loading from '../loading';
import './Welcome.scss';

const WelcomePage = ({ match }) => {
  const [loading, setLoading] = useState(false);
  useEffect(async () => {
    if (match.isExact) {
      try {
        const res = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_BACKEND_URL}/users/verify/${match.params.confirmationCode}`,
        });
        setLoading(true);
        console.log('Responses', res);
      } catch (err) {
        console.log('Error', err);
      }
    }
  }, []);

  if (loading) {
    return (
      <div className="welcome-wrapper">
        <Welcome className="welcome-img" />
        <h3>
          Welcome Back, Please Proceed to
          {' '}
          <Link to="/signin">Sign in </Link>
        </h3>
      </div>
    );
  }
  return (
    <>
      <Loading />
      <h2 className="verified">Getting you verified</h2>
    </>
  );
};

WelcomePage.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      confirmationCode: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default WelcomePage;
