/* eslint-disable no-console */
import React from 'react';
import {
  Banner,
  Input, Visualize, Share,
} from '../../iconComponents';

import './index.scss';

const index = () => {
  console.log('Home/Index');
  return (
    <div className="main-wrapper">
      <header className="banner-wrapper" id="header">
        <Banner className="banner-img" />
        <div className="text-block">
          <h2>Re-Invent how you visualize your data</h2>
          <p className="text-description">
            Graph API offers a unique way to input data, visualize data
            and embed graph on your website.
            Get the most out of your data!
          </p>
        </div>
      </header>
      <div className="content-wrapper">
        <h1 className="content-header">GRAPH API shows you the big picture</h1>
        <div className="three-tiers">
          <div className="tier tier-1">
            <Input className="tier-img input-img" />
            <div className="tier-text">
              <h2>Input Data</h2>
              <p>
                Graph API accepts input file data from user in two formats,
                CSV and JSON file format. The CSV allows two other formats,
                It could be horizontal or verrical format.
                The application also accepts CSV files export from excel spreadsheet.
              </p>
            </div>
          </div>
          <div className="tier tier-2">
            <Visualize className="tier-img" />
            <div className="tier-text">
              <h2>Visualize Data</h2>
              <p>
                The System gives a visual presentation of the data that was inputed.
                It displays different types of graph and also provide an embed button
                and a copy link button which is used to share the graph.
              </p>
            </div>
          </div>
          <div className="tier tier-3">
            <Share className="tier-img" />
            <div className="tier-text">
              <h2>Embed Graph</h2>
              <p>
                Graph generated can be shared and embedded into client applications,
                using the Iframe tag in HTML.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
