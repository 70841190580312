/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import { GiPadlockOpen } from 'react-icons/gi';
import * as Yup from 'yup';
import axios from 'axios';
import TextError from './TextError';
import Flash from '../utils/Flash';
import { Context } from '../../store/store';
import authActions from '../../store/actions/authActions';
import Btnloader from '../loading/Btnloader';
import './Signup.scss';

const Signin = () => {
  const [, , authState, authDispatch] = useContext(Context);
  const { loader, user, message } = authState;
  const { LOADING, USER_SIGNED_IN, MODAL } = authActions;
  const [messenger, setMessenger] = useState('');
  const history = useHistory();
  const initialValues = {
    email: '',
    password: '',
  };
  const onSubmit = (values, { resetForm }) => {
    console.log('Form values', values);
    setMessenger(false);
    authDispatch({ type: LOADING, payload: true });
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BACKEND_URL}/users/signin`,
      data: values,
    })
    // eslint-disable-next-line consistent-return
      .then((res) => {
        console.log(res);
        if (res.data.status === 403) {
          setMessenger(true);
          authDispatch({ type: LOADING, payload: false });
          authDispatch({ type: MODAL, payload: res.data.msg });
        } else {
          localStorage.setItem('user', JSON.stringify(res.data));
          const userSignIn = localStorage.getItem('user');
          history.push('/projects');
          authDispatch({ type: USER_SIGNED_IN, payload: JSON.parse(userSignIn) });
          authDispatch({ type: LOADING, payload: false });
        }
      })
      .catch((err) => {
        setMessenger(true);
        authDispatch({ type: MODAL, payload: 'Error Occured, Please try again' });
        authDispatch({ type: LOADING, payload: false });
        console.log(err); // Flash Message Network Error
      });
    resetForm({});
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid Email format')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });
  if (!user) {
    return (
      <>
        {messenger && (
          <Flash>
            {message}
          </Flash>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form className="sign-form">
            <div className="sign-icon">
              <GiPadlockOpen size="5rem" color="white" />
            </div>
            <h1>Sign In</h1>
            <div className="form-control">
              <label htmlFor="email">Email</label>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder="johndoe@mail.com"
                autoComplete="new-off"
              />
              <ErrorMessage name="email" component={TextError} />
            </div>
            <div className="form-control">
              <label htmlFor="password">Password</label>
              <Field
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                autoComplete="new-off"
              />
              <ErrorMessage name="password" component={TextError} />
            </div>
            <div className="redirect-signin"><Link to="/forgotPassword">Forgot Password?</Link></div>
            <div className="redirect-signin dont">
              Don&apos;t have an account?
              {' '}
              <Link to="/signup">Sign up</Link>
            </div>
            <Button type="submit" className="btn">
              {loader ? <Btnloader /> : 'Sign In'}
            </Button>
          </Form>
        </Formik>
      </>
    );
  }
  return <Redirect to="/" />;
};

export default Signin;
