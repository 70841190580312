import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import CSV_HORIZONTAL_K3 from './CSV_HORIZONTAL_K3.csv';
import CSV_VERTICAL_SMALL_K10 from './CSV_VERTICAL_SMALL_K10.csv';

import SampleCsvFile from './files/Sample1.csv';
import SampleJsonFile from './files/Sample2.json';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    marginTop: '5%',
    fontSize: 'small !important',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
  },
  listMain: {
    width: '100%',
    textAlign: 'left',
    color: theme.palette.text.primary,
    fontSize: 'small',
  },
  iconSize: {
    fontSize: 'small !important',
    minWidth: '20%',
  },
  stepPaper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
    marginTop: '1%',
  },
  smallStepPaper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
    marginTop: '0%',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export default function FullWidthGrid() {
  const classes = useStyles();
  const [width] = useState(window.innerWidth - (window.innerWidth * 0.45));
  const barCsvDataOption = useMemo(() => {
    const data = [
      {
        Legends: 'A',
        Part1: 5,
        Part2: 16,
        Part3: 16,
        Part4: 4,
        Part5: 11,
      },
      {
        Legends: 'B',
        Part1: 7,
        Part2: 12,
        Part3: 19,
        Part4: 16,
        Part5: 9,
      },
      {
        Legends: 'C',
        Part1: 20,
        Part2: 6,
        Part3: 4,
        Part4: 1,
        Part5: 11,
      },
      {
        Legends: 'D',
        Part1: 15,
        Part2: 11,
        Part3: 4,
        Part4: 4,
        Part5: 3,
      },
      {
        Legends: 'E',
        Part1: 2,
        Part2: 3,
        Part3: 7,
        Part4: 17,
        Part5: 6,
      },
      {
        Legends: 'F',
        Part1: 13,
        Part2: 4,
        Part3: 10,
        Part4: 3,
        Part5: 13,
      },
      {
        Legends: 'G',
        Part1: 13,
        Part2: 1,
        Part3: 18,
        Part4: 10,
        Part5: 13,
      },
      {
        Legends: 'H',
        Part1: 19,
        Part2: 9,
        Part3: 2,
        Part4: 17,
        Part5: 19,
      },
      {
        Legends: 'I',
        Part1: 14,
        Part2: 11,
        Part3: 15,
        Part4: 9,
        Part5: 12,
      },
      {
        Legends: 'J',
        Part1: 6,
        Part2: 20,
        Part3: 10,
        Part4: 18,
        Part5: 3,
      },
      {
        Legends: 'K',
        Part1: 20,
        Part2: 7,
        Part3: 16,
        Part4: 4,
        Part5: 12,
      },
      {
        Legends: 'L',
        Part1: 19,
        Part2: 20,
        Part3: 4,
        Part4: 8,
        Part5: 7,
      },
      {
        Legends: 'M',
        Part1: 16,
        Part2: 11,
        Part3: 14,
        Part4: 3,
        Part5: 5,
      },
      {
        Legends: 'N',
        Part1: 10,
        Part2: 5,
        Part3: 19,
        Part4: 13,
        Part5: 3,
      },
      {
        Legends: 'O',
        Part1: 3,
        Part2: 20,
        Part3: 2,
        Part4: 20,
        Part5: 3,
      },
      {
        Legends: 'P',
        Part1: 10,
        Part2: 19,
        Part3: 9,
        Part4: 16,
        Part5: 17,
      },
      {
        Legends: 'Q',
        Part1: 16,
        Part2: 17,
        Part3: 3,
        Part4: 19,
        Part5: 10,
      },
      {
        Legends: 'R',
        Part1: 2,
        Part2: 20,
        Part3: 5,
        Part4: 4,
        Part5: 12,
      },
      {
        Legends: 'S',
        Part1: 17,
        Part2: 20,
        Part3: 20,
        Part4: 5,
        Part5: 7,
      },
      {
        Legends: 'T',
        Part1: 20,
        Part2: 14,
        Part3: 14,
        Part4: 14,
        Part5: 1,
      },
      {
        Legends: 'U',
        Part1: 15,
        Part2: 10,
        Part3: 7,
        Part4: 2,
        Part5: 17,
      },
      {
        Legends: 'V',
        Part1: 10,
        Part2: 16,
        Part3: 5,
        Part4: 2,
        Part5: 17,
      },
      {
        Legends: 'W',
        Part1: 15,
        Part2: 5,
        Part3: 18,
        Part4: 12,
        Part5: 5,
      },
      {
        Legends: 'X',
        Part1: 14,
        Part2: 3,
        Part3: 7,
        Part4: 7,
        Part5: 3,
      },
      {
        Legends: 'Y',
        Part1: 3,
        Part2: 11,
        Part3: 11,
        Part4: 6,
        Part5: 8,
      },
      {
        Legends: 'Z',
        Part1: 10,
        Part2: 4,
        Part3: 14,
        Part4: 20,
        Part5: 9,
      },
    ];

    return {
      chart: {
        type: 'column',
        width,
      },
      title: {
        text: 'BAR - CSV VERTICAL K5',
      },
      yAxis: {
        title: {
          text: 'Total',
        },
      },
      xAxis: {
        categories: data.map(item => item.Legends),
        title: {
          text: null,
        },
      },
      subtitle: {
        text: 'CSV_VERTICAL_K5.csv',
      },
      series: Array.from({ length: 5 }).map((_, index) => ({
        name: `Bar${index + 1}`,
        data: data.map(item => item[`Part${index + 1}`]),
      })),
    };
  }, [width]);

  const lineJsonDataOption = useMemo(() => {
    const data = {
      1: {
        A: 16,
        B: 35,
        C: 48,
        D: 12,
        E: 45,
      },
      2: {
        A: 25,
        B: 35,
        C: 58,
        D: 50,
        E: 67,
      },
      3: {
        A: 28,
        B: 12,
        C: 36,
        D: 15,
        E: 16,
      },
      4: {
        A: 28,
        B: 12,
        C: 36,
        D: 25,
        E: 26,
      },
      5: {
        A: 10,
        B: 48,
        C: 14,
        D: 35,
        E: 36,
      },
      6: {
        A: 28,
        B: 75,
        C: 91,
        D: 50,
        E: 67,
      },
      7: {
        A: 16,
        B: 35,
        C: 48,
        D: 51,
        E: 63,
      },
      8: {
        A: 25,
        B: 35,
        C: 58,
        D: 59,
        E: 46,
      },
      9: {
        A: 28,
        B: 12,
        C: 36,
        D: 89,
        E: 12,
      },
      10: {
        A: 28,
        B: 12,
        C: 36,
        D: 59,
        E: 47,
      },
    };
    const labels = ['A', 'B', 'C', 'D', 'E'];
    return {
      chart: {
        type: 'line',
        width,
      },
      title: {
        text: 'LINE - JSON K5',
      },
      yAxis: {
        title: {
          text: 'Population',
        },
      },
      subtitle: {
        text: 'JSON_K5.json',
      },
      series: labels.map(label => ({
        name: label,
        data: Object.keys(data).map(key => {
          const item = data[key];
          return item[label];
        }),
      })),
    };
  }, [width]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2} justifyContent="center" xs={12}>
        <Grid item xs={8}>
          <h1 item xs={8} className={classes.paper}>Graph Api</h1>
        </Grid>
        <Grid container item xs={8}>
          <Paper item xs={10} className={classes.paper} style={{ width: 'inherit' }}>
            <p item className={classes.paper}>
              Graph API helps users visualize data easily.
              It structures user’s data into graphs for easier visualization and analysis.
            </p>
          </Paper>
        </Grid>
        <Grid container item xs={8}>
          <h2 item xs={8} className={classes.paper}>Features:</h2>
        </Grid>
        <Grid container item xs={8} spacing={2}>
          <Grid item xs={3}>
            <Paper className={classes.paper}>Accepted input file formats</Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper className={classes.paper}>
              <List disablePadding>
                It accepts data in two file formats (and sample files):
                <ListItem>
                  <ListItemIcon style={{ minWidth: '2%' }}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <p item xs={8} className={classes.paper}>CSV:</p>
                </ListItem>
                <ListItem>
                  <List component="div" disablePadding>
                    <ListItem>
                      <ListItemIcon style={{ minWidth: '2%' }}>
                        <RadioButtonUncheckedIcon />
                      </ListItemIcon>
                      <p item xs={8} className={classes.paper}>
                        There are two orientations supported for CSV: horizonal and vertical.
                      </p>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon style={{ minWidth: '2%' }}>
                        <RadioButtonUncheckedIcon />
                      </ListItemIcon>
                      <p item xs={8} className={classes.paper}>
                        Horizontal Format: 1st row is reserved for Legend names.
                        This will be used for future release where Legends are
                        automatically taken from the file provided.
                      </p>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon style={{ minWidth: '2%' }}>
                        <RadioButtonUncheckedIcon />
                      </ListItemIcon>
                      <p item xs={8} className={classes.paper}>
                        Vertical Format: 1st column is reserved for Legend Names
                      </p>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon style={{ minWidth: '2%' }}>
                        <RadioButtonUncheckedIcon />
                      </ListItemIcon>
                      <p item xs={8} className={classes.paper}>
                        In case your file do not have any legends,
                        just leave the 1st row/column as blank depending on your orientation
                        and start your data from the 2ndrow/column.
                      </p>
                    </ListItem>
                    <ListItem>
                      <a download href={CSV_HORIZONTAL_K3} item xs={8} className={classes.paper}>
                        CSV_HORIZONTAL_K3
                      </a>
                      <a
                        download
                        href={CSV_VERTICAL_SMALL_K10}
                        item
                        xs={8}
                        className={classes.paper}
                      >
                        CSV_VERTICAL_K5
                      </a>
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  <ListItemIcon style={{ minWidth: '2%' }}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <p item xs={8} className={classes.paper}>JSON:</p>
                </ListItem>
                <ListItem>
                  <List component="div" disablePadding>
                    <ListItem>
                      <a download href="https://drive.google.com/uc?id=1C3apsQ4s4LYiEWeZG4AOWviM5cNT2Een&export=download" item xs={8} className={classes.paper}>JSON_K5</a>
                    </ListItem>
                  </List>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item xs={8} spacing={2}>
          <Grid item xs={3}>
            <Paper className={classes.paper}>Graph Output</Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper className={classes.paper}>
              <List disablePadding>
                You can choose from: Line, Bar, and Area graphs
              </List>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item xs={8} spacing={2}>
          <Grid item xs={3}>
            <Paper className={classes.paper}>Share projects</Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper className={classes.paper}>
              <List disablePadding>
                You can share your output graphs to other users
                just by copy-pasting the URL on your project.
                <br />
                No need for other users to create an account with us to view your link.
              </List>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item xs={8} spacing={2}>
          <Grid item xs={3}>
            <Paper className={classes.paper}>Embed into your own domains</Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper className={classes.paper}>
              <List disablePadding>
                Embed your output graphs to your own domains by clicking “Embed”
                <br />
                button on your project and copy-pasting the URL to your website.
                <br />
                It is that easy!
              </List>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item xs={8} dir>
          <Grid container item xs={12}>
            <h2 item xs={12} className={classes.paper}>Sample schema and charts:</h2>
          </Grid>
          <Grid item xs={12} className={classes.paper}>
            <Paper item className={classes.stepPaper}>
              <ListItem>
                <List disablePadding>
                  <p>
                    1. Bar Chart
                    <a
                      download
                      href={SampleCsvFile}
                      className={classes.paper}
                    >
                      CSV_VERTICAL_K5.csv
                    </a>
                  </p>
                  <ListItem>
                    <p item xs={12} className={classes.stepPaper}>
                      ⸰ Using CSV file format in Vertical orientation
                    </p>
                  </ListItem>
                  <ListItem>
                    <p item xs={12} className={classes.smallStepPaper}>
                      ⸰ Includes 5 legends
                    </p>
                  </ListItem>
                  <ListItem>
                    <HighchartsReact highcharts={Highcharts} options={barCsvDataOption} />
                  </ListItem>
                </List>
              </ListItem>

            </Paper>

            <Paper item className={classes.stepPaper}>

              <ListItem classes="mt-4">
                <List disablePadding>
                  <p>
                    2. Line Chart
                    <a
                      download="sample.json"
                      href={window.URL.createObjectURL(new Blob([JSON.stringify(SampleJsonFile, null, 2)], { type: 'text/json' }))}
                      className={classes.paper}
                    >
                      JSON_K5.json
                    </a>
                  </p>
                  <ListItem>
                    <p item xs={8} className={classes.stepPaper}>⸰ Using JSON file format</p>
                  </ListItem>
                  <ListItem>
                    <p item xs={8} className={classes.smallStepPaper}>
                      ⸰ Includes 5 legends
                    </p>
                  </ListItem>
                  <ListItem>
                    <HighchartsReact highcharts={Highcharts} options={lineJsonDataOption} />
                  </ListItem>
                </List>
              </ListItem>
            </Paper>
          </Grid>
        </Grid>

        <Grid container item xs={8} dir>
          <Grid container item xs={8}>
            <h2 item xs={8} className={classes.paper}>How to create project:</h2>
          </Grid>
          <Grid item xs={8} className={classes.paper}>
            <List container spacing={2} disablePadding>
              <Paper item className={classes.stepPaper}>
                <ListItem>
                  <p item xs={8}>1. Click Create Project</p>
                </ListItem>
              </Paper>
              <Paper item className={classes.stepPaper}>
                <ListItem>
                  <List disablePadding>
                    <p>2. Fill up the mandatory details:</p>
                    <ListItem>
                      <p item xs={8} className={classes.stepPaper}>a. Project Name / Graph Name</p>
                    </ListItem>
                    <ListItem>
                      <p item xs={8} className={classes.stepPaper}>
                        b. Subtitle / Details of your data
                      </p>
                    </ListItem>
                    <ListItem>
                      <p item xs={8} className={classes.stepPaper}>
                        c. Y-axis legend (i.e. Total,Sales,etc.)
                      </p>
                    </ListItem>
                  </List>
                </ListItem>
              </Paper>
              <Paper item className={classes.stepPaper}>
                <ListItem>
                  <p item xs={8}>3. Select your chart type: Line, Bar, or Area</p>
                </ListItem>
              </Paper>
              <Paper item className={classes.stepPaper}>
                <ListItem>
                  <p item xs={8}>4. Choose your input file type: CSV or JSON</p>
                </ListItem>
              </Paper>
              <Paper item className={classes.stepPaper}>
                <ListItem>
                  <p item xs={8}>5. Enter all the Legends for your data</p>
                </ListItem>
              </Paper>
              <Paper item className={classes.stepPaper}>
                <ListItem>
                  <p item xs={8}>6. Click “Create”</p>
                </ListItem>
              </Paper>
              <Paper item className={classes.stepPaper}>
                <ListItem>
                  <p item xs={8}>7. Upload your input file</p>
                </ListItem>
              </Paper>
              <Paper item className={classes.stepPaper}>
                <ListItem>
                  <p item xs={8}>8. View your graph!</p>
                </ListItem>
              </Paper>
            </List>
          </Grid>
        </Grid>
        <Grid container item xs={8} dir>
          <Grid item xs={8}>
            <h2 item xs={8} className={classes.paper}>Limitations:</h2>
          </Grid>
          <Grid item xs={12} className={classes.paper}>
            <Paper className={classes.paper}>
              <List disablePadding>
                <ListItem>
                  <ListItemIcon style={{ minWidth: '2%' }}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <p item xs={8} className={classes.paper}>
                    We are currently running on BETA mode.
                    Please expect some issues while using the app.
                  </p>
                </ListItem>
                <ListItem>
                  <ListItemIcon style={{ minWidth: '2%' }}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <p item xs={8} className={classes.paper}>
                    The data uploaded to the project is secured but not encrypted.
                    Please do not upload confidential information.
                  </p>
                </ListItem>
                <ListItem>
                  <ListItemIcon style={{ minWidth: '2%' }}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <p item xs={8} className={classes.paper}>
                    Legends is currently limited to 10.
                  </p>
                </ListItem>
                <ListItem>
                  <ListItemIcon style={{ minWidth: '2%' }}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <p item xs={8} className={classes.paper}>
                    Please make sure that you follow the
                    allowed format shared in “Templates Repository”.
                    Invalid format can result to graphs not being loaded,
                    projects not being created, or incorrect representation of data.
                  </p>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
