/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useState } from 'react';
import axios from 'axios';
import { useParams, Redirect } from 'react-router-dom';
import './jsonform.scss';

const JsonForm = () => {
  const { id } = useParams();
  const [gotograph, setGoToGraph] = useState(false);

  const Upload = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = async (e) => {
      const data = JSON.parse(e.target.result);
      const jsonTitle = Object.keys(data);
      const ObjectData = data[jsonTitle];
      const dataArray = Object.keys(ObjectData);
      const keys = Object.keys(ObjectData[dataArray[0]]);
      const dataKeys = [];
      // eslint-disable-next-line no-plusplus
      for (let x = 0; x < keys.length; x++) {
        dataKeys.push([]);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < dataArray.length; i++) {
          // eslint-disable-next-line no-prototype-builtins
          if (ObjectData.hasOwnProperty(dataArray[i])) {
            const regex = /[,]/g;
            if (regex.test(ObjectData[dataArray[i]][keys[x]])) {
              const result = ObjectData[dataArray[i]][keys[x]].replace(regex, '');
              dataKeys[x].push(Number(result));
            } else {
              dataKeys[x].push(ObjectData[dataArray[i]][keys[x]]);
            }
          }
        }
      }
      const payload = {
        jsonData: [dataArray, ...dataKeys],
        projectId: id,
      };
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/json/create`,
          method: 'POST',
          data: payload,
        });
        if (response) {
          setGoToGraph(true);
        }
      } catch (err) {
        console.log(err); // Dispatch Custom modal here
      }
    };
  };

  if (gotograph) {
    return <Redirect to={`/project/${id}/graph`} />;
  }

  return (
    <div className="form-wrapper">
      <form className="form">
        <label htmlFor="json">JSON Upload</label>
        <input
          type="file"
          accept=".json"
          id="json"
          className="json-input"
          onChange={Upload}
        />
      </form>
    </div>
  );
};

export default JsonForm;
