/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import {
  FiTwitter,
  FiMail, FiFacebook, FiLinkedin,
} from 'react-icons/fi';
import { RiArrowUpSLine } from 'react-icons/ri';
import './footer.scss';

const Footer = () => (
  <div className="footer" data-test="footerComponent">
    <div className="logo-area">
      <h4>GRAPH API</h4>
      <div className="soc-icon">
        <a rel="noreferrer" href="http://twitter.com/saveondev" target="_blank"><FiTwitter title="Twitter" /></a>
        <a rel="noreferrer" href="mailto:help@saveondev.com" target="_blank"><FiMail title="Mail" /></a>
        <a rel="noreferrer" href="http://facebook.com/saveondev" target="_blank"><FiFacebook title="Facebook" /></a>
        <a rel="noreferrer" href="http://linkedin.com/company/saveondev" target="_blank"><FiLinkedin title="linkedin" /></a>
      </div>
    </div>
    <div className="logo-area">
      <h4>Company</h4>
      <div className="links-div">
        <a href="https://saveondev.com/blog/" target="_blank" rel="noreferrer"><u>Blog</u></a>
        <a href="https://saveondev.com/careers/" target="_blank" rel="noreferrer"><u>Career</u></a>
        <a href="https://saveondev.com" target="_blank" rel="noreferrer"><u>Saveondev</u></a>
      </div>
    </div>
    <div className="logo-area">
      <h4>Support</h4>
      <div className="links-div">
        <a href="https://saveondev.com/faq/" target="_blank" rel="noreferrer"><u>FAQ</u></a>
        <a href="https://saveondev.com/how-we-help/digital-services/" target="_blank" rel="noreferrer"><u>Help</u></a>
        <a href="mailto:help@saveondev.com" target="_blank" rel="noreferrer"><u>Contact Us</u></a>
      </div>
    </div>
    <a href="#header-container" className="arrow-up"><RiArrowUpSLine /></a>
  </div>
);

export default Footer;
