import React from 'react';
import { Link } from 'react-router-dom';
import { Error } from '../../iconComponents';
import './error.scss';

const ErrorPage = () => (
  <div className="error-wrapper">
    <h2 className="err-header">Oops! an Error Occured</h2>
    <Error className="error-img" />
    <p className="back-home">
      Lets get you back
      {' '}
      <Link to="/">home</Link>
    </p>
  </div>
);

export default ErrorPage;
