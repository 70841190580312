import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Store from './store/store';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './components/Home';
import Forms from './components/forms/Forms';
import JsonForm from './components/forms/JsonForm';
import Graph from './components/graph/Graph';
import Projects from './components/project';
import Project from './components/project/Project';
import NewProject from './components/project/NewProject';
import Signin from './components/auth/Signin';
import Signup from './components/auth/Signup';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import Templates from './components/templates/index';
import ErrorPage from './components/Error/Error';
import WelcomePage from './components/auth/Welcome';
import Interceptors from './Interceptors';
import './App.scss';

function App() {
  useEffect(() => {
    Interceptors();
  }, []);

  if (useRouteMatch('/project/:id/embed')) {
    return (
      <Store className="app">
        <Route exact path="/project/:id/embed" component={Project} />
      </Store>
    );
  }
  return (
    <Store>
      <div className="app" data-test="appComponent">
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/templates" component={Templates} />
          <Route exact path="/forgotPassword" component={ForgotPassword} />
          <Route exact path="/resetPassword/:confirmationCode" component={ResetPassword} />
          <Route exact path="/verify/:confirmationCode" component={WelcomePage} />
          <Route exact path="/new-project/:id/csv-upload" component={Forms} />
          <Route exact path="/new-project/:id/json-upload" component={JsonForm} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/project/:id" component={Project} />
          <Route exact path="/new-project" component={NewProject} />
          <Route exact path="/project/:id/graph" component={Graph} />
          <Route path="*" component={ErrorPage} />
        </Switch>
        <Footer />
      </div>
    </Store>
  );
}

export default App;
