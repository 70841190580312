/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import TextError from './TextError';
import authActions from '../../store/actions/authActions';
import { Context } from '../../store/store';
import Btnloader from '../loading/Btnloader';
import Flash from '../utils/Flash';
import Loading from '../loading';

import './resetPassword.scss';

const ResetPassword = ({ match }) => {
  console.log('resetting password...');
  const [, , authState, authDispatch] = useContext(Context);
  const { user, message } = authState;
  const { USER_SIGNED_IN, MODAL } = authActions;
  const [loader, setLoader] = useState(false);
  const [messenger, setMessenger] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState('');

  useEffect(async () => {
    console.log('UseEffect reset password...');
    if (match.isExact) {
      try {
        const res = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_BACKEND_URL}/users/verify/${match.params.confirmationCode}`,
        });
        setLoading(true);
        setMail(res.data.email);
        console.log('Responses', res);
      } catch (err) {
        console.log('Error', err);
      }
    }
  }, []);

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const onSubmit = (values, { resetForm }) => {
    console.log('Form Values', values);
    setLoader(true);
    setMessenger(false);
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BACKEND_URL}/users/resetPassword`,
      data: values,
      params: {
        email: mail,
      },
    })
      .then((res) => {
        console.log(res);
        localStorage.setItem('user', JSON.stringify(res.data));
        const userSignIn = localStorage.getItem('user');
        authDispatch({ type: USER_SIGNED_IN, payload: JSON.parse(userSignIn) });
        setLoader(false);
        // authDispatch({ type: MODAL, payload: res.data.msg });
        setMessenger(true);
      })
      .catch((err) => {
        console.log(err);
        setMessenger(true);
        setLoader(false);
        authDispatch({ type: MODAL, payload: 'Error Occured, Please refresh and try again!' });
      });
    resetForm({});
  };

  const validationSchema = Yup.object({
    password: Yup.string().required('New Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  if (user) {
    return <Redirect to="/" />;
  }

  if (loading) {
    return (
      <>
        {messenger && (
          <Flash>
            {message}
          </Flash>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form className="reset-form">
            <h1>Reset Password</h1>
            <div className="form-control">
              <label htmlFor="email">Email</label>
              <div id="email" className="reset-mail">
                { mail }
              </div>
            </div>
            <div className="form-control">
              <label htmlFor="password">New Password</label>
              <Field type="password" id="password" name="password" placeholder="Password" />
              <ErrorMessage name="password" component={TextError} />
            </div>
            <div className="form-control">
              <label htmlFor="confirm-password">Confirm Password</label>
              <Field type="password" id="confirm-password" name="confirmPassword" placeholder="Confirm New Password" />
              <ErrorMessage name="confirmPassword" component={TextError} />
            </div>
            <button type="submit" className="btn">{loader ? <Btnloader /> : 'Reset' }</button>
          </Form>
        </Formik>
      </>
    );
  }

  return (
    <>
      <Loading />
      <h2 style={{ fontWeight: 300 }}>Just a moment!</h2>
    </>
  );
};

ResetPassword.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      confirmationCode: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ResetPassword;
