/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useState, useContext } from 'react';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import TextError from './TextError';
import authActions from '../../store/actions/authActions';
import { Context } from '../../store/store';
import Btnloader from '../loading/Btnloader';
import Flash from '../utils/Flash';

import './forgotPassword.scss';

const ForgotPassword = () => {
  const [, , authState, authDispatch] = useContext(Context);
  const { message } = authState;
  const { MODAL } = authActions;
  const [loader, setLoader] = useState(false);
  const [messenger, setMessenger] = useState(false);
  const initialValues = {
    email: '',
  };

  const onSubmit = (values, { resetForm }) => {
    console.log('Form Values', values);
    setLoader(true);
    setMessenger(false);
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BACKEND_URL}/users/forgotPassword`,
      data: values,
    })
      .then((res) => {
        console.log(res);
        authDispatch({ type: MODAL, payload: res.data.msg });
        setLoader(false);
        setMessenger(true);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        authDispatch({ type: MODAL, payload: 'Error occured, please try again' });
        setMessenger(true);
      });
    resetForm({});
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid Email format')
      .required('Email is required'),
  });

  return (
    <>
      {messenger && (
        <Flash>
          {message}
        </Flash>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form className="forget-form">
          <h1>Forgot Password</h1>
          <div className="form-control">
            <label htmlFor="email">Email</label>
            <div className="form-wrap">
              <Field
                type="email"
                id="email"
                name="email"
                placeholder="johndoe@mail.com"
              />
              <button type="submit" className="btn">
                { loader ? <Btnloader /> : 'Submit' }
              </button>
            </div>
            <ErrorMessage name="email" component={TextError} />
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default ForgotPassword;
