/* eslint-disable no-plusplus */
/* eslint-disable no-console */
// /* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import {
  RadioGroup,
  Radio, FormControlLabel, FormLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { CSVReader } from 'react-papaparse';
import './form.scss';

const buttonRef = React.createRef();

class CSVFileReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      orientation: 'vertical',
    };
    this.handleOrientation = this.handleOrientation.bind(this);
  }

  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  }

  handleOnFileLoad = async (data) => {
    const { match: { params: { id } } } = this.props;
    const { orientation } = this.state;
    //  |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
    let dataArr = [];
    let rest = [];
    let graphData;
    if (orientation === 'vertical') {
      console.log('Handle Vertical file');
      graphData = this.handleVerticalFile(data);
    } else {
      console.log('Handle Horizontal file');
      graphData = this.handleHorizontalFile(data);
    }
    // eslint-disable-next-line prefer-const
    [dataArr, ...rest] = graphData;

    const keys = [];
    for (let i = 0; i < rest.length; i++) {
      keys.push([]);
      for (let x = 0; x < rest[i].length; x++) {
        const regex = /[,]/g;
        let result;
        if (regex.test(rest[i][x])) {
          result = rest[i][x].replace(regex, '');
          keys[i].push(Number(result));
        } else {
          keys[i].push(Number(rest[i][x]));
        }
      }
    }
    const payload = {
      csvData: [dataArr, ...keys],
      projectId: id,
      orientation,
    };
    console.log('Payload', payload);
    const response = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BACKEND_URL}/csv/create`,
      data: payload,
    });
    if (response.status === 200) {
      console.log('Updating state Redirect');
      this.setState({
        redirect: true,
      });
    }
    console.log(response);
  }

  handleVerticalFile = (data) => {
    const myArr = [];
    const graphData = data.map((item) => item.data);
    console.log('GraphData', graphData);
    for (let i = 0; i < graphData[0].length; i++) {
      myArr.push([]);
      for (let j = 0; j < graphData.length; j++) {
        myArr[i].push(graphData[j][i]);
      }
    }
    const [dataArr, ...rest] = myArr;
    return [dataArr, ...rest];
  }

  handleHorizontalFile = (data) => {
    const graphData = data.map((item) => item.data);
    console.log('graphData', graphData);
    const [dataArr, ...rest] = graphData;
    for (let i = 0; i < rest.length; i++) {
      rest[i].splice(0, 1);
    }
    dataArr.splice(0, 1);
    return [dataArr, ...rest];
  }

  handleOrientation = (e) => {
    const { value } = e.target;
    console.log('Value from radio', value);
    if (value === 'vertical') {
      this.setState({
        orientation: 'vertical',
      });
    } else {
      this.setState({
        orientation: 'horizontal',
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  // handleOnError = (err, file, inputElem, reason) => {
  //   console.log(err);
  // }

  handleOnRemoveFile = (data) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  }

  handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  }

  render() {
    console.log(this.state);
    const { redirect, orientation } = this.state;

    if (redirect) {
      console.log('I am in redirecting soon');
      // console.log(this.props);
      const { match: { params: { id } } } = this.props;
      return (
        <Redirect to={`/project/${id}/graph`} />
      );
    }
    return (
      <div className="aside-wrapper">
        <CSVReader
          ref={buttonRef}
          onFileLoad={this.handleOnFileLoad} //   handleVerticalFile
          onError={this.handleOnError}
          noClick
          noDrag
          onRemoveFile={this.handleOnRemoveFile} //  handleVerticalFile
        >
          {({ file }) => (
            <>
              <aside className="aside">
                <h2 style={{ paddingBottom: '1rem' }}>CSV Upload</h2>
                <input type="hidden" value={orientation} required />
                <FormLabel component="legend" style={{ marginTop: '1.5rem', fontSize: '1.5rem', fontWeight: 'bolder' }}>File Orientation</FormLabel>
                <RadioGroup style={{ marginBottom: '1.5rem' }} aria-label="orientation" name="orientation" onChange={this.handleOrientation} row>
                  <FormControlLabel
                    value="vertical"
                    control={<Radio />}
                    label={<span style={{ fontSize: '1.5rem' }}>Vertical</span>}
                    labelPlacement="end"
                    checked={orientation === 'vertical'}
                  />
                  <FormControlLabel
                    value="horizontal"
                    control={<Radio />}
                    label={<span style={{ fontSize: '1.5rem' }}>Horizontal</span>}
                    labelPlacement="end"
                  />
                </RadioGroup>
                <div className="div-aside">
                  <button
                    type="button"
                    onClick={this.handleOpenDialog}
                    className="upload-btn"
                  >
                    Choose a file
                  </button>
                  <div className="filename">
                    {file && file.name}
                  </div>
                  <button
                    type="submit"
                    className="remove-file"
                    onClick={this.handleRemoveFile}
                  >
                    Remove
                  </button>
                </div>
              </aside>
            </>
          )}
        </CSVReader>
      </div>
    );
  }
}

CSVFileReader.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default CSVFileReader;
