/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useParams, Link, useRouteMatch } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import Flash from '../utils/Flash';
import Loading from '../loading';
import './Project.scss';

const Project = () => {
  const { id } = useParams();
  const [width, setWidth] = useState(window.innerWidth - (window.innerWidth * 0.3));
  const [graphData, setGraphData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [orientation, setOrientation] = useState('');
  const [message, setMessage] = useState('');

  const url = `${window.location.href}#graph`;

  const handleCopyClick = () => {
    setMessage(false);
    setMessage(`Copied from ${process.env.REACT_APP_FRONTEND_URL}`);
  };

  useEffect(async () => {
    const response = await axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/project/detail`,
      method: 'GET',
      params: {
        projectId: id,
      },
    });
    const project = response.data.result;
    if (project.fileType === 'csv file') {
      await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}/csv/detail`,
        params: {
          projectId: id,
        },
      })
        .then(res => {
          const { csvData, orientation } = res.data.result;
          console.log(res.data.result);
          setGraphData(csvData);
          setOrientation(orientation);
        })
        .catch(err => {
          console.log(err); //  Use custom Modal toget Error if there is
        });
    } else {
      await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_BACKEND_URL}/json/detail`,
        params: {
          projectId: id,
        },
      })
        .then(res => {
          if (res.data.result === null) {
            setGraphData([]);
          } else {
            const { jsonData } = res.data.result;
            setGraphData(jsonData);
          }
        })
        .catch(err => {
          console.log(err); //  Use custom Modal toget Error if there is
        });
    }

    setProjectData(project);

    function handleResize() {
      setWidth(window.innerWidth - (window.innerWidth * 0.1));
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  if (graphData.length > 0) {
    const {
      projectTitle, subtitle, yAxis, keys, chartType,
    } = projectData;
    const series = [];
    for (let i = 0; i < keys.length; i++) {
      let x = i;
      x += 1;
      series.push({
        type: chartType,
        name: keys[i].keyName,
        data: orientation === 'vertical' ? graphData[x].filter((item, index) => index !== 0) : graphData[x],
      });
    }
    const options = {
      chart: {
        type: chartType,
        width,
      },
      title: {
        text: projectTitle,
      },
      subtitle: {
        text: subtitle,
      },
      yAxis: {
        title: {
          text: yAxis,
        },
      },
      xAxis: {
        categories: orientation === 'vertical' ? graphData[0].filter((item, index) => index !== 0) : graphData[0], // Data for x-axis
      },
      tooltip: {
        shared: true,
        crosshairs: true,
      },
      credits: {
        enabled: false,
      },
      series,
    };
    return (

      <div id="graph" className="graph">
        {message && (
        <Flash>
          {message}
        </Flash>
        )}
        <HighchartsReact highcharts={Highcharts} options={options} />
        <div style={{ display: 'flex' }}>
          {!useRouteMatch(`/project/${id}/embed`)
          && (
            <Link to={`/project/${id}/embed`}>
              {'\xa0\xa0\xa0\xa0\xa0Embed\xa0\xa0\xa0\xa0\xa0'}
            </Link>
          )}
          { useRouteMatch(`/project/${id}/embed`)
          && (
            <CopyToClipboard text={url}>
              <Link href="#/" component="button" onClick={handleCopyClick}>
                <FaCopy />
                {' '}
                Copy URL
              </Link>
            </CopyToClipboard>
          )}
          { !useRouteMatch(`/project/${id}/embed`)
          && (
          <CopyToClipboard text={url}>
            <Link href="#/" component="button" onClick={handleCopyClick}>
              <FaCopy />
              {' '}
              Copy URL
            </Link>
          </CopyToClipboard>
          )}
        </div>
      </div>
    );
  }
  return (
    <>
      <Loading />
      <h2
        style={
          {
            fontWeight: '300',
            fontSize: '2rem',
            padding: '1rem',
            textAlign: 'center',
          }
        }
      >
        Getting your data
      </h2>
    </>
  );
};
export default Project;
