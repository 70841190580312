import authAction from '../actions/authActions';

const {
  LOADING,
  USER_SIGNED_IN,
  USER_SIGNED_OUT,
  MODAL,
} = authAction;
const authReducer = (state, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loader: action.payload };
    case MODAL:
      return { ...state, message: action.payload };
    case USER_SIGNED_IN:
      return { ...state, user: action.payload };
    case USER_SIGNED_OUT:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default authReducer;
