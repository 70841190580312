/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { AiFillPlusCircle } from 'react-icons/ai';
import { ImBin } from 'react-icons/im';
import { BsPencil } from 'react-icons/bs';
import { Context } from '../../store/store';
import projectActions from '../../store/actions/projectActions';
import authActions from '../../store/actions/authActions';
import Flash from '../utils/Flash';
import './NewProject.scss';

const {
  ADD_KEY,
  EDIT_KEY,
  IS_EDITING,
  NOT_EDITING,
  DELETE_KEY,
  ADD_PROJECT, GET_PROJECT_ID, RESET_KEY,
} = projectActions;

const NewProject = () => {
  const [state, dispatch, authState, authDispatch] = useContext(Context);
  const { user, message } = authState;
  const [loader, setLoader] = useState(false);
  const { MODAL } = authActions;
  const [keyName, setKeyName] = useState('');
  const [messenger, setMessenger] = useState(false);
  const [jsonRedirect, setJsonRedirect] = useState(false);
  const [csvRedirect, setCsvRedirect] = useState(false);

  useEffect(() => {
    setLoader(false);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: ADD_PROJECT, payload: name, value });
  };

  const addKey = (e) => {
    e.preventDefault();
    if (state.key.length >= 10 && !state.isEditing) {
      setKeyName('');
      setMessenger(true);
      authDispatch({ type: MODAL, payload: 'Key Limit Reached!' });
      return;
    }
    if (keyName.length >= 3 && state.isEditing) {
      dispatch({ type: EDIT_KEY, payload: keyName });
      dispatch({ type: NOT_EDITING });
    } else if (keyName.length >= 3) {
      const newKey = { id: new Date().getTime().toString(), keyName };
      dispatch({ type: ADD_KEY, payload: newKey });
    } else {
      setMessenger(true);
      authDispatch({ type: MODAL, payload: 'Key name has to be greater than two letter words' });
    }
    setKeyName('');
  };

  const newKeyName = (e) => {
    setKeyName(e.target.value);
    setMessenger(false);
  };

  const handleDelete = (id) => {
    dispatch({ type: DELETE_KEY, payload: id });
  };

  const resetFormValue = () => {
    state.project.projectTitle = '';
    state.project.subtitle = '';
    state.project.yAxis = '';
    state.project.chartType = '';
    dispatch({ type: RESET_KEY, payload: [] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessenger(false);
    console.log('Submit is triggered');
    if (state.key < 1) {
      setMessenger(true);
      authDispatch({ type: MODAL, payload: 'Please Add at least one key name and Try again!' });
      return;
    }
    setLoader(true);
    const payload = {
      projectTitle: state.project.projectTitle,
      subtitle: state.project.subtitle,
      yAxis: state.project.yAxis,
      keys: state.key,
      // eslint-disable-next-line no-underscore-dangle
      user: user._id,
      fileType: state.project.fileType,
      chartType: state.project.chartType,
    };
    //  Axios
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BACKEND_URL}/project`,
      data: payload,
    })
      .then(async res => {
        console.log('Server Response', res);
        const { status, project } = await res.data;
        if (res.data.status === 500) {
          setMessenger(true);
          console.log('Err', res);
          authDispatch({ type: MODAL, payload: 'Error Occured, Please Try again!' });
          setLoader(false);
          return;
        }
        if (status === 200) {
          dispatch({ type: GET_PROJECT_ID, payload: project._id });
          if (state.project.fileType === 'json file') {
            // Dispatch an action to redirect either csv upload or Json upload
            console.log('Json is true');
            setJsonRedirect(true);
          } else {
            console.log('csv is true');
            setCsvRedirect(true);
          }
        }
        setLoader(false);
        resetFormValue();
      })
      .catch(err => {
        setMessenger(true);
        setLoader(false);
        authDispatch({ type: MODAL, payload: err.response.data.msg });
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: IS_EDITING, payload: id });
    const newKey = state.key.find((item) => item.id === id);
    setKeyName(newKey.keyName);
  };

  if (jsonRedirect) {
    console.log('Am redirecting to json upload');
    return <Redirect to={`/new-project/${state.currentProjectId}/json-upload`} />;
  }
  if (csvRedirect) {
    console.log('Am redirecting to csv upload');
    return <Redirect to={`/new-project/${state.currentProjectId}/csv-upload`} />;
  }
  return (
    <>
      {messenger && (
        <Flash>
          {message}
        </Flash>
      )}
      <div className="Form-wrapper">
        <form className="form" id="form" onSubmit={handleSubmit}>
          <h1>Create Project</h1>
          <div>
            <input
              type="text"
              placeholder="Project title*"
              name="projectTitle"
              value={state.project.projectTitle}
              onChange={handleChange}
              autoComplete="new-off"
              required
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Subtitle*"
              name="subtitle"
              value={state.project.subtitle}
              onChange={handleChange}
              autoComplete="new-off"
              required
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Y-axis Label*"
              name="yAxis"
              value={state.project.yAxis}
              onChange={handleChange}
              autoComplete="new-off"
              required
            />
          </div>
          <div>
            <select name="chartType" onChange={handleChange}>
              <option value="" selected disabled>Select Chart Type</option>
              <option value="column">Bar</option>
              <option value="line">Line</option>
              <option value="area">Area</option>
            </select>
          </div>
          <div>
            <select name="fileType" onChange={handleChange}>
              <option value="" selected disabled>Choose File Type</option>
              <option value="csv file">CSV File</option>
              <option value="json file">JSON File</option>
            </select>
          </div>
          <section className="key-section">
            <h2 className="keys-header">Legends</h2>
            <div className="key-form">
              <input
                type="text"
                placeholder="Legend*"
                name="keyName"
                value={keyName}
                autoComplete="new-off"
                onChange={newKeyName}
              />
              <Button
                className="key-btn"
                onClick={addKey}
                type="submit"
              >
                <AiFillPlusCircle size={25} />
              </Button>
            </div>
            <div className="key-container">
              <ul className="key-ordered-list">
                {state.key.map(({ id, keyName }) => (
                  <li key={id} className="key-list">
                    <p>{keyName}</p>
                    <div className="icon-buttons">
                      <div className="button" onClick={() => handleEdit(id)}>
                        <BsPencil title="edit" color="green" />
                      </div>
                      <div className="button" onClick={() => handleDelete(id)}>
                        <ImBin title="delete" color="red" />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <div className="submit-btn">
            <input
              type="submit"
              value={loader ? 'Loading...' : 'CREATE'}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default NewProject;
