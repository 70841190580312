import React from 'react';
import PropTypes from 'prop-types';
import FlashMessage from 'react-flash-message';
import './flash.scss';

const Flash = ({ children }) => (
  <FlashMessage>
    <div className="flash-message">
      { children }
    </div>
  </FlashMessage>
);

Flash.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Flash;
