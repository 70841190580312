const initialState = {
  key: [],
  project: {
    projectTitle: '', subtitle: '', yAxis: '', keys: [], fileType: '', chartType: '',
  },
  isEditing: false,
  editId: null,
  projects: [],
  currentProjectId: 0,
  redirect: null,
  jsonResponse: {},
  graphData: [],
  projectData: {},
  isMount: false,
};

export default initialState;
