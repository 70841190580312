import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { Chip } from '@material-ui/core';
import { Context } from '../../store/store';
import authActions from '../../store/actions/authActions';
import './header.scss';

const { USER_SIGNED_IN, USER_SIGNED_OUT } = authActions;
const Header = () => {
  const [, , authState, authDispatch] = useContext(Context);

  const handleSignOut = () => {
    authDispatch({ type: USER_SIGNED_OUT, payload: null });
    localStorage.clear();
  };
  useEffect(() => {
    const userSignIn = localStorage.getItem('user');
    authDispatch({ type: USER_SIGNED_IN, payload: JSON.parse(userSignIn) });
  }, []);
  const { user } = authState;

  return (
    <div data-test="headerComponent" className="header-container" id="header-container">
      <div className="navbar-brand">
        <Link to="/" className="link">
          {'Graph Api -  '}
          <span>
            <Chip
              label="BETA"
              size="small"
              style={{
                backgroundColor: 'red',
                color: 'white',
              }}
            />
          </span>
        </Link>
      </div>
      <nav className="nav">
        {user && (
          <>
            <Link to="/new-project" className="btn">
              <FaPlus className="plus" />
              Create Project
            </Link>
            <Link to="/templates" className="link">
              User Manual
            </Link>
            <Link to="/projects" className="link">
              {user.firstname}
            </Link>
            <Link to="/" className="link" onClick={handleSignOut}>
              Sign Out
            </Link>
          </>
        )}
        {!user && (
          <>
            <Link to="/signin" className="link">
              Sign in
            </Link>
            <Link to="/signup" className="link">
              Sign up
            </Link>
          </>
        )}
      </nav>
    </div>
  );
};

export default Header;
