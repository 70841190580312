import React from 'react';
import PropTypes from 'prop-types';
import './TextError.scss';

const TextError = ({ children }) => (
  <div className="error">
    Error:
    {' '}
    {children}
  </div>
);

TextError.propTypes = {
  children: PropTypes.string.isRequired,
};

export default TextError;
