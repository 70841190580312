/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

function SvgInput(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1000 700" // "0 0 995 697.681"
      {...props}
    >
      <circle cx={39.536} cy={110.727} r={13.631} fill="#e6e6e6" />
      <circle cx={80.428} cy={110.727} r={13.631} fill="#e6e6e6" />
      <circle cx={121.319} cy={110.727} r={13.631} fill="#e6e6e6" />
      <circle cx={162.211} cy={110.727} r={13.631} fill="#e6e6e6" />
      <circle cx={203.103} cy={110.727} r={13.631} fill="#e6e6e6" />
      <circle cx={243.994} cy={110.727} r={13.631} fill="#e6e6e6" />
      <circle cx={284.886} cy={110.727} r={13.631} fill="#e6e6e6" />
      <path
        d="M316.384 127.326H8.038a5.553 5.553 0 01-5.547-5.547V99.675a5.553 5.553 0 015.547-5.547h308.346a5.553 5.553 0 015.547 5.547v22.104a5.553 5.553 0 01-5.547 5.547zM8.038 96.346a3.332 3.332 0 00-3.328 3.329v22.104a3.332 3.332 0 003.328 3.328h308.346a3.332 3.332 0 003.328-3.328V99.675a3.332 3.332 0 00-3.328-3.328zM74.026 3.187a3.19 3.19 0 013.187 3.187v69.063a3.19 3.19 0 01-3.187 3.187H6.374a3.19 3.19 0 01-3.187-3.187V6.374a3.19 3.19 0 013.187-3.187h67.652m0-3.187H6.374A6.374 6.374 0 000 6.374v69.063a6.374 6.374 0 006.374 6.374h67.652a6.374 6.374 0 006.374-6.374V6.374A6.374 6.374 0 0074.026 0z"
        fill="#e6e6e6"
      />
      <path
        d="M63.474 21.34H18.337a1.594 1.594 0 110-3.186h45.137a1.594 1.594 0 110 3.187zM63.474 32.625H18.337a1.594 1.594 0 110-3.187h45.137a1.594 1.594 0 110 3.187zM63.474 43.91H18.337a1.594 1.594 0 110-3.188h45.137a1.594 1.594 0 110 3.187zM63.474 55.194H18.337a1.594 1.594 0 110-3.187h45.137a1.594 1.594 0 110 3.187zM63.474 66.478H18.337a1.594 1.594 0 110-3.187h45.137a1.594 1.594 0 010 3.187z"
        fill="#6c63ff"
      />
      <path
        d="M305.087 3.187a3.19 3.19 0 013.187 3.187v69.063a3.19 3.19 0 01-3.187 3.187h-67.652a3.19 3.19 0 01-3.187-3.187V6.374a3.19 3.19 0 013.187-3.187h67.652m0-3.187h-67.652a6.374 6.374 0 00-6.374 6.374v69.063a6.374 6.374 0 006.374 6.374h67.652a6.374 6.374 0 006.374-6.374V6.374A6.374 6.374 0 00305.087 0z"
        fill="#e6e6e6"
      />
      <path
        d="M294.535 21.34h-45.137a1.594 1.594 0 110-3.186h45.137a1.594 1.594 0 010 3.187zM294.535 32.625h-45.137a1.594 1.594 0 110-3.187h45.137a1.594 1.594 0 010 3.187zM294.535 43.91h-45.137a1.594 1.594 0 110-3.188h45.137a1.594 1.594 0 010 3.187zM294.535 55.194h-45.137a1.594 1.594 0 110-3.187h45.137a1.594 1.594 0 010 3.187zM294.535 66.478h-45.137a1.594 1.594 0 110-3.187h45.137a1.594 1.594 0 010 3.187zM535.026 135.978a3.19 3.19 0 013.187 3.187v69.063a3.19 3.19 0 01-3.187 3.187h-67.652a3.19 3.19 0 01-3.187-3.187v-69.063a3.19 3.19 0 013.187-3.187h67.652m0-3.187h-67.652a6.374 6.374 0 00-6.374 6.375v69.062a6.374 6.374 0 006.374 6.374h67.652a6.374 6.374 0 006.374-6.374v-69.063a6.374 6.374 0 00-6.374-6.374z"
        fill="#6c63ff"
      />
      <path
        d="M524.474 154.132h-45.137a1.594 1.594 0 110-3.187h45.137a1.594 1.594 0 010 3.187zM524.474 165.417h-45.137a1.594 1.594 0 010-3.187h45.137a1.594 1.594 0 010 3.187zM524.474 176.7h-45.137a1.594 1.594 0 110-3.187h45.137a1.594 1.594 0 010 3.187zM524.474 187.985h-45.137a1.594 1.594 0 110-3.187h45.137a1.594 1.594 0 010 3.187zM524.474 199.27h-45.137a1.594 1.594 0 110-3.187h45.137a1.594 1.594 0 010 3.187z"
        fill="#6c63ff"
      />
      <path
        d="M190.442 81.81h-69.423a7.09 7.09 0 01-7.082-7.08V25.052a7.09 7.09 0 017.082-7.082h69.423a7.09 7.09 0 017.082 7.082V74.73a7.09 7.09 0 01-7.082 7.082zm-69.423-60.652a3.9 3.9 0 00-3.895 3.895V74.73a3.899 3.899 0 003.895 3.895h69.423a3.9 3.9 0 003.895-3.895V25.053a3.9 3.9 0 00-3.895-3.895z"
        fill="#e6e6e6"
      />
      <path
        d="M178.3 40.2h-45.138a1.594 1.594 0 010-3.187H178.3a1.594 1.594 0 110 3.187zM178.3 51.485h-45.138a1.594 1.594 0 010-3.187H178.3a1.594 1.594 0 110 3.187zM178.3 62.769h-45.138a1.594 1.594 0 010-3.187H178.3a1.594 1.594 0 110 3.187z"
        fill="#6c63ff"
      />
      <path
        d="M420.8 109.338l-66.676-19.336a7.09 7.09 0 01-4.829-8.774l13.836-47.71a7.09 7.09 0 018.774-4.83l66.676 19.336a7.09 7.09 0 014.83 8.775l-13.836 47.71a7.09 7.09 0 01-8.775 4.829zm-49.783-77.589a3.9 3.9 0 00-4.825 2.656l-13.836 47.71a3.899 3.899 0 002.656 4.826l66.676 19.336a3.9 3.9 0 004.826-2.655l13.836-47.71a3.9 3.9 0 00-2.656-4.827z"
        fill="#e6e6e6"
      />
      <path
        d="M420.728 65.992L377.377 53.42a1.594 1.594 0 01.887-3.06l43.351 12.57a1.594 1.594 0 11-.887 3.062zM417.585 76.83l-43.351-12.572a1.594 1.594 0 11.887-3.06l43.351 12.57a1.594 1.594 0 01-.887 3.062zM414.442 87.667L371.09 75.095a1.594 1.594 0 01.888-3.06l43.35 12.571a1.594 1.594 0 01-.887 3.061z"
        fill="#6c63ff"
      />
      <path
        fill="#e6e6e6"
        d="M156.106 607.775h699.313v3H156.106zM562.14 603.085H448.421l3.855-50.112h106.009l3.855 50.112z"
      />
      <path fill="#e6e6e6" d="M442.639 597.303h127.21v11.565h-127.21z" />
      <path
        d="M723.08 230.128H288.444a11.565 11.565 0 00-11.564 11.564v265.022h457.765V241.692a11.565 11.565 0 00-11.565-11.564z"
        fill="#3f3d56"
      />
      <path
        d="M276.88 506.714v43.367a11.565 11.565 0 0011.564 11.565H723.08a11.565 11.565 0 0011.565-11.565v-43.367zM300.009 254.221h412.47v231.292h-412.47z"
        fill="#e6e6e6"
      />
      <circle cx={506.244} cy={533.698} r={11.565} fill="#3f3d56" />
      <circle cx={312.87} cy={261.795} r={2.195} fill="#fff" />
      <circle cx={321.2} cy={261.795} r={2.195} fill="#fff" />
      <circle cx={329.531} cy={261.795} r={2.195} fill="#fff" />
      <path fill="#fff" d="M310.675 269.253h389.916V463.5H310.675z" />
      <path
        d="M336.594 298.799a1.38 1.38 0 00-1.378 1.378v69.676a1.38 1.38 0 001.378 1.378h80.464a1.38 1.38 0 001.379-1.378v-69.676a1.38 1.38 0 00-1.379-1.378zM465.79 299.023a1.38 1.38 0 00-1.379 1.378v69.452a1.38 1.38 0 001.379 1.378h80.208a1.38 1.38 0 001.378-1.378v-69.452a1.38 1.38 0 00-1.378-1.378zM593.63 298.295a1.38 1.38 0 00-1.377 1.378v70.18a1.38 1.38 0 001.378 1.378h81.042a1.38 1.38 0 001.378-1.378v-70.18a1.38 1.38 0 00-1.378-1.378zM338.065 405.72a2.85 2.85 0 000 5.7h75.653a2.85 2.85 0 000-5.7z"
        fill="#e6e6e6"
      />
      <path
        d="M338.065 419.276a2.85 2.85 0 000 5.7h34.062a2.85 2.85 0 000-5.7z"
        fill="#6c63ff"
      />
      <path
        d="M468.65 405.72a2.85 2.85 0 000 5.7h75.652a2.85 2.85 0 000-5.7z"
        fill="#e6e6e6"
      />
      <path
        d="M468.65 419.276a2.85 2.85 0 000 5.7h34.062a2.85 2.85 0 000-5.7z"
        fill="#6c63ff"
      />
      <path
        d="M595.97 405.72a2.85 2.85 0 000 5.7h75.652a2.85 2.85 0 000-5.7zM338.065 391.72a2.85 2.85 0 000 5.7h75.653a2.85 2.85 0 000-5.7zM468.65 391.72a2.85 2.85 0 000 5.7h75.652a2.85 2.85 0 000-5.7zM595.97 391.72a2.85 2.85 0 000 5.7h75.652a2.85 2.85 0 000-5.7z"
        fill="#e6e6e6"
      />
      <path
        d="M595.97 419.276a2.85 2.85 0 000 5.7h34.061a2.85 2.85 0 000-5.7z"
        fill="#6c63ff"
      />
    </svg>
  );
}

export default SvgInput;
