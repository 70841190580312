/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

function SvgVisualize(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 995 697.681"
      {...props}
    >
      <path
        d="M750.864 279.44l2.798-22.427a30.285 30.285 0 0160.103 7.499l-2.798 22.426a4.073 4.073 0 01-4.54 3.533l-52.03-6.49a4.073 4.073 0 01-3.533-4.541z"
        fill="#2f2e41"
      />
      <circle cx={775.786} cy={263.927} r={22.204} fill="#ffb8b8" />
      <path
        d="M746.328 257.92a23.984 23.984 0 0126.738-20.806l4.486.56a23.983 23.983 0 0120.806 26.737l-.056.449-9.479-1.183-2.103-9.456-1.776 8.972-4.9-.61-1.06-4.772-.897 4.527-31.815-3.97z"
        fill="#2f2e41"
      />
      <path
        d="M667.19 343.131a10.228 10.228 0 0015.284 3.515l20.167 11.814 10.168-10.505-28.714-16.283a10.283 10.283 0 00-16.905 11.459z"
        fill="#ffb8b8"
      />
      <path
        d="M733.286 364.359c-14.875 0-41.052-6.66-42.501-7.031l-.458-.117 4.12-20.86 39.982 7.898 21.72-30.587 24.938-2.535-.696.916c-.325.426-32.47 42.743-37.473 49.841-1.262 1.791-4.868 2.475-9.632 2.475z"
        fill="#3f3d56"
      />
      <path
        d="M737.274 442.647l-.595-.284c-.127-.06-12.765-6.208-19.311-18.21-6.515-11.943 23.983-62.611 26.116-66.127l.031-16.09 10.972-29.622 13.991-7.908-12.013 28.03z"
        fill="#3f3d56"
      />
      <path
        fill="#ffb8b8"
        d="M723.257 615.591l-13.372-.001-6.362-51.579 19.736.001-.002 51.579z"
      />
      <path
        d="M726.667 628.553l-43.118-.002v-.545a16.784 16.784 0 0116.783-16.782H726.668z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb8b8"
        d="M866.891 603.872l-12.707 4.163-22.107-47.033 18.755-6.145 16.059 49.015z"
      />
      <path
        d="M874.168 615.128l-40.974 13.425-.17-.518a16.784 16.784 0 0110.722-21.174h.001l25.026-8.2zM841 595.439l-38.12-63.64-32.18-67.994-28.959 60.06-11.504 77.789-40.343.56.16-.63 61.737-245.311 48.352 7.224-2.201 31.916 1.31 1.864c10.923 15.517 22.217 31.56 15.74 48.853l18.362 54.523L874 591.439z"
        fill="#2f2e41"
      />
      <path
        d="M754 409.439c-7.006 0-15-8-10.853-21.658l-.269-.195 9.961-44.007c-8.692-12.432 2.768-22.692 3.842-23.608l5.47-9.847 24.139-15.309 12.212 101.475-.18.172c-8.82 8.44-38.445 12.977-44.322 12.977z"
        fill="#6c63ff"
      />
      <path
        d="M803.433 432.337a11.881 11.881 0 01-2.851-.302c-.939-.236-2.459-1.43-4.807-9.077-9.336-30.398-20.36-121.86-12.35-130.81l.182-.206 13.15 2.191c1.092-.806 6.828-4.81 12.12-4.114a8.033 8.033 0 015.497 3.25l.09.118 4.19 59.594L834 423.111l-.368.168c-.893.406-20.055 9.058-30.199 9.058z"
        fill="#3f3d56"
      />
      <path
        d="M794.59 409.044a10.228 10.228 0 009.663-12.353l19.248-13.258-5.204-13.662-26.92 19.104a10.283 10.283 0 003.213 20.169z"
        fill="#ffb8b8"
      />
      <path
        d="M817.15 394.056l-17.146-12.573 24.1-32.864-18.494-32.638 8.275-23.662.534 1.02c.25.474 24.948 47.526 29.257 55.067 4.483 7.844-24.97 43.76-26.226 45.285z"
        fill="#3f3d56"
      />
      <path
        d="M431.48 541.975l-14.593-6.145-10.01-73.151H273.573l-10.85 72.852-13.056 6.528a3.1 3.1 0 001.387 5.873h179.223a3.1 3.1 0 001.203-5.957z"
        fill="#e6e6e6"
      />
      <path
        d="M668.371 472.411H13.542A12.973 12.973 0 01.598 459.438v-92.34h680.717v92.34a12.974 12.974 0 01-12.944 12.973z"
        fill="#ccc"
      />
      <path
        d="M682 410.74H0V15.64A15.657 15.657 0 0115.64 0h650.72A15.657 15.657 0 01682 15.64z"
        fill="#3f3d56"
      />
      <path
        d="M641.267 382.064H40.733a12.07 12.07 0 01-12.057-12.057V40.733a12.07 12.07 0 0112.057-12.057h600.534a12.07 12.07 0 0112.057 12.057v329.274a12.07 12.07 0 01-12.057 12.057z"
        fill="#fff"
      />
      <path
        d="M509.42 548.695H171.972a1.567 1.567 0 01-1.54-1.134 1.53 1.53 0 011.478-1.919h337.385a1.615 1.615 0 011.616 1.194 1.528 1.528 0 01-1.491 1.859z"
        fill="#ccc"
      />
      <path fill="#f2f2f2" d="M489.86 81.909h144.996v110.773H489.86z" />
      <path fill="#fff" d="M497.065 87.414h130.586v99.765H497.065z" />
      <path
        d="M564.651 161.414h.052a34.817 34.817 0 00.779-69.49.794.794 0 00-.601.209.786.786 0 00-.257.575l-.761 67.902a.797.797 0 00.788.804z"
        fill="#3f3d56"
      />
      <path
        d="M537.346 103.152a1.025 1.025 0 01.72.308l23.375 23.906a1.014 1.014 0 01.291.725l-.364 32.491a1.01 1.01 0 01-.33.74 1.026 1.026 0 01-.772.268 35.044 35.044 0 01-23.7-58.09 1.026 1.026 0 01.742-.347h.038z"
        fill="#e6e6e6"
      />
      <path
        d="M561.116 91.647a1.03 1.03 0 01.699.286 1.011 1.011 0 01.314.747l-.329 29.316a1.02 1.02 0 01-1.75.702l-20.398-20.86a1.023 1.023 0 01.055-1.482 35.118 35.118 0 0121.345-8.708l.064-.001zM550.607 175.495a6.253 6.253 0 11-6.183-6.323 6.26 6.26 0 016.183 6.323z"
        fill="#6c63ff"
      />
      <path
        d="M568.473 175.695a6.253 6.253 0 11-6.183-6.323 6.26 6.26 0 016.183 6.323z"
        fill="#3f3d56"
      />
      <path
        d="M586.339 175.896a6.253 6.253 0 11-6.183-6.324 6.26 6.26 0 016.183 6.324z"
        fill="#e6e6e6"
      />
      <path fill="#f2f2f2" d="M488.333 210.116h144.996v110.773H488.333z" />
      <path fill="#fff" d="M495.538 215.621h130.586v99.765H495.538z" />
      <circle cx={517.695} cy={242.988} r={5.422} fill="#3f3d56" />
      <path
        d="M604.812 238.769a3.677 3.677 0 110 7.354h-57.025a3.677 3.677 0 110-7.354h57.025m0-.9h-57.025a4.578 4.578 0 100 9.155h57.025a4.578 4.578 0 100-9.156z"
        fill="#3f3d56"
      />
      <path
        d="M582.311 247.024h-42.928a4.578 4.578 0 110-9.156h42.928a4.578 4.578 0 010 9.156z"
        fill="#6c63ff"
      />
      <circle cx={517.695} cy={265.503} r={5.422} fill="#3f3d56" />
      <path
        d="M604.812 261.284a3.677 3.677 0 110 7.354h-57.025a3.677 3.677 0 110-7.354h57.025m0-.9h-57.025a4.578 4.578 0 100 9.155h57.025a4.578 4.578 0 100-9.156z"
        fill="#3f3d56"
      />
      <path
        d="M598.522 269.539h-59.14a4.578 4.578 0 110-9.156h59.14a4.578 4.578 0 010 9.156z"
        fill="#6c63ff"
      />
      <circle cx={517.695} cy={288.018} r={5.422} fill="#3f3d56" />
      <path
        d="M604.812 283.799a3.677 3.677 0 110 7.354h-57.025a3.677 3.677 0 110-7.354h57.025m0-.901h-57.025a4.578 4.578 0 100 9.155h57.025a4.578 4.578 0 100-9.155z"
        fill="#3f3d56"
      />
      <path
        d="M563.399 292.053h-24.016a4.578 4.578 0 110-9.155h24.016a4.578 4.578 0 010 9.155z"
        fill="#6c63ff"
      />
      <path fill="#f2f2f2" d="M51.188 101.508h405.093v207.61H51.188z" />
      <path fill="#fff" d="M64.692 111.824h378.087v186.978H64.692z" />
      <path
        d="M395.888 270.277H120.27a.863.863 0 01-.863-.863V137.31a.863.863 0 111.725 0v131.242h274.756a.863.863 0 010 1.725z"
        fill="#3f3d56"
      />
      <path
        d="M179.157 260.789H154.09a2.563 2.563 0 01-2.56-2.56V223.67a2.563 2.563 0 012.56-2.56h25.068a2.563 2.563 0 012.56 2.56v34.557a2.563 2.563 0 01-2.56 2.56zM224.873 260.789h-25.069a2.563 2.563 0 01-2.56-2.56v-67.335a2.563 2.563 0 012.56-2.56h25.069a2.563 2.563 0 012.56 2.56v67.334a2.563 2.563 0 01-2.56 2.56zM270.589 260.789H245.52a2.563 2.563 0 01-2.56-2.56V223.67a2.563 2.563 0 012.56-2.56h25.069a2.563 2.563 0 012.56 2.56v34.557a2.563 2.563 0 01-2.56 2.56zM316.304 260.789h-25.068a2.507 2.507 0 01-2.56-2.445V177.84a2.507 2.507 0 012.56-2.445h25.068a2.507 2.507 0 012.56 2.445v80.504a2.507 2.507 0 01-2.56 2.445zM362.02 260.789H336.95a2.563 2.563 0 01-2.56-2.56V154.665a2.563 2.563 0 012.56-2.56h25.069a2.563 2.563 0 012.56 2.56v103.562a2.563 2.563 0 01-2.56 2.56z"
        fill="#6c63ff"
      />
      <circle cx={166.623} cy={205.585} r={5.175} fill="#3f3d56" />
      <circle cx={212.339} cy={171.945} r={5.175} fill="#3f3d56" />
      <circle cx={258.054} cy={205.585} r={5.175} fill="#3f3d56" />
      <circle cx={303.77} cy={155.556} r={5.175} fill="#3f3d56" />
      <circle cx={349.486} cy={136.58} r={5.175} fill="#3f3d56" />
      <path
        fill="#3f3d56"
        d="M258.163 206.744l-45.824-34.323-45.199 33.854-1.034-1.38 46.233-34.63 45.606 34.16 45.321-49.595.181-.074 45.716-18.419.645 1.6-45.534 18.346-46.111 50.461z"
      />
      <path
        d="M941.547 631.439h-381a1 1 0 010-2h381a1 1 0 010 2z"
        fill="#ccc"
      />
    </svg>
  );
}

export default SvgVisualize;
