/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

function SvgWriteWall(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M438.926 452.675c7.184 12.699 1.092 55.586 1.092 55.586s-39.89-16.886-47.073-29.579a26.413 26.413 0 1145.98-26.007z"
        fill="#f1f1f1"
      />
      <path
        d="M440.596 508.206l-.847.178c-8.162-38.778-36.666-65.075-36.953-65.336l.583-.64c.289.262 28.996 26.738 37.217 65.798z"
        fill="#fff"
      />
      <path
        d="M504.197 467.134c-9.748 17.683-64.706 41.639-64.706 41.639s-9.061-59.264.681-76.941a36.556 36.556 0 1164.025 35.302z"
        fill="#f1f1f1"
      />
      <path
        d="M439.976 509.414l-.826-.869c39.77-37.769 50.065-90.445 50.164-90.973l1.178.222c-.1.531-10.458 53.577-50.516 91.62z"
        fill="#fff"
      />
      <path
        data-name="Rectangle 62"
        fill="#e6e6e6"
        d="M.301 19.335h705.265v334.66H.301z"
      />
      <path
        data-name="Rectangle 75"
        fill="#fff"
        d="M20.468 48.033h664.931v279.218H20.468z"
      />
      <path
        data-name="Rectangle 80"
        fill="#6c63ff"
        d="M0 0h705.265v29.962H0z"
      />
      <circle
        data-name="Ellipse 90"
        cx={22.265}
        cy={15.628}
        r={5.553}
        fill="#fff"
      />
      <circle
        data-name="Ellipse 91"
        cx={43.344}
        cy={15.628}
        r={5.553}
        fill="#fff"
      />
      <circle
        data-name="Ellipse 92"
        cx={64.423}
        cy={15.628}
        r={5.553}
        fill="#fff"
      />
      <path
        data-name="Path 142"
        d="M281.261 132.323c-1.495.005-2.704 2.052-2.701 4.573.003 2.514 1.21 4.55 2.701 4.556h278.442c1.495-.005 2.704-2.053 2.701-4.574-.003-2.514-1.21-4.55-2.7-4.555z"
        fill="#e6e6e6"
      />
      <path
        data-name="Path 143"
        d="M281.261 159.712c-1.495.005-2.704 2.053-2.701 4.574.003 2.513 1.21 4.55 2.701 4.555h205.753c1.495-.005 2.704-2.053 2.701-4.573-.003-2.514-1.21-4.55-2.7-4.556z"
        fill="#e6e6e6"
      />
      <path
        data-name="Path 142"
        d="M281.261 186.482c-1.495.005-2.704 2.052-2.701 4.573.003 2.514 1.21 4.55 2.701 4.556h278.442c1.495-.005 2.704-2.053 2.701-4.574-.003-2.514-1.21-4.55-2.7-4.555z"
        fill="#e6e6e6"
      />
      <path
        data-name="Path 143"
        d="M281.261 213.871c-1.495.005-2.704 2.053-2.701 4.574.003 2.513 1.21 4.55 2.701 4.555h205.753c1.495-.005 2.704-2.053 2.701-4.573-.003-2.514-1.21-4.551-2.7-4.556z"
        fill="#e6e6e6"
      />
      <path
        data-name="Path 142"
        d="M65.187 233.323c-1.494.005-2.704 2.052-2.7 4.573.002 2.514 1.21 4.55 2.7 4.556H202.63c1.494-.005 2.704-2.053 2.7-4.574-.002-2.514-1.21-4.55-2.7-4.555z"
        fill="#e6e6e6"
      />
      <path
        data-name="Path 143"
        d="M65.187 260.712c-1.494.005-2.704 2.053-2.7 4.574.002 2.513 1.21 4.55 2.7 4.555h64.754c1.495-.005 2.704-2.053 2.7-4.573-.002-2.514-1.21-4.55-2.7-4.556z"
        fill="#e6e6e6"
      />
      <path
        d="M186.52 117.606a55.71 55.71 0 10-96.27 52.92c.5.63 1.02 1.26 1.55 1.87.04.04.07.08.11.12a54.137 54.137 0 004.15 4.28 40 40 0 001.18 1.05c.76.68 1.54 1.33 2.35 1.95a54.775 54.775 0 004.94 3.47l.06.03a56.8 56.8 0 003.39 1.94l.47.24c1.08.56 2.18 1.09 3.31 1.58.23.1.48.2.71.3.02.01.03.01.05.02a54.115 54.115 0 007.02 2.37 55.732 55.732 0 0014.37 1.88c1.77 0 3.52-.08 5.25-.25a55.508 55.508 0 0011.64-2.35c.01 0 .02-.01.03-.01a54.605 54.605 0 006.99-2.77c.01-.01.03-.01.04-.02.51-.24 1-.5 1.5-.75l.24-.12c.4-.21.8-.42 1.2-.64 1-.55 1.98-1.13 2.95-1.75.27-.16.53-.33.8-.51.88-.58 1.75-1.2 2.59-1.83.15-.11.31-.22.46-.34a55.692 55.692 0 0018.92-62.68z"
        fill="#fff"
      />
      <circle
        data-name="Ellipse 188"
        cx={152.294}
        cy={150.531}
        r={8.349}
        fill="#2f2e41"
      />
      <path
        data-name="Path 969"
        d="M154.62 142.412a8.35 8.35 0 017.35 12.312 8.347 8.347 0 10-13.868-9.172 8.33 8.33 0 016.519-3.14z"
        fill="#2f2e41"
      />
      <circle
        data-name="Ellipse 189"
        cx={136.692}
        cy={125.987}
        r={24.526}
        fill="#2f2e41"
      />
      <path
        data-name="Path 970"
        d="M117.443 110.77a24.522 24.522 0 0133.99-2.572c-.2-.19-.4-.383-.607-.568a24.526 24.526 0 00-32.73 36.536l.14.124c.208.185.422.36.635.537a24.522 24.522 0 01-1.427-34.057z"
        fill="#2f2e41"
      />
      <circle
        data-name="Ellipse 190"
        cx={136.484}
        cy={134.509}
        r={15.796}
        fill="#a0616a"
      />
      <path
        d="M164.75 175.666v5.3c-.97.62-1.95 1.2-2.95 1.75-.4.22-.8.43-1.2.64l-.24.12c-.5.25-.99.51-1.5.75-.01.01-.03.01-.04.02a54.605 54.605 0 01-6.99 2.77c-.01 0-.02.01-.03.01a55.508 55.508 0 01-11.64 2.35c-1.73.17-3.48.25-5.25.25a55.732 55.732 0 01-14.37-1.88 54.115 54.115 0 01-7.02-2.37c-.02-.01-.03-.01-.05-.02-.23-.1-.48-.2-.71-.3-1.13-.49-2.23-1.02-3.31-1.58l-.47-.24a56.8 56.8 0 01-3.39-1.94l-.06-.03a54.775 54.775 0 01-4.94-3.47c-.81-.62-1.59-1.27-2.35-1.95a40 40 0 01-1.18-1.05 54.137 54.137 0 01-4.15-4.28 20.23 20.23 0 01.76-3.07l.01-.01a4.49 4.49 0 01.21-.5 3.989 3.989 0 01.19-.42c1.79-3.35 5.03-4.18 9.05-4.36 2.27-.1 4.78.01 7.43-.02 1.4-.01 2.85-.06 4.3-.19 9.15-.83 7.49-6.23 7.9-9.14.41-2.82 2.95-.38 3.12-.22l.01.01a32.272 32.272 0 0016.6 2.91 32.3 32.3 0 012.94-.22c2.58-.03 2.02.71 1.13 1.32a11.183 11.183 0 01-1.35.76s-.41 2.5-.83 6.24c-.4 3.58 6.46 5.26 7.03 5.39a.06.06 0 00.04.01 13.774 13.774 0 017.61.2h.01a9.018 9.018 0 015.68 6.46z"
        fill="#cbcbcb"
      />
      <path
        d="M113.62 182.096c0 .74-.02 1.45-.05 2.12-.01.4-.03.79-.05 1.16-.02-.01-.03-.01-.05-.02-.23-.1-.48-.2-.71-.3-1.13-.49-2.23-1.02-3.31-1.58l-.47-.24a56.8 56.8 0 01-3.39-1.94c.03-.35.08-.7.13-1.05.11-.77.2-1.55.26-2.32a32.184 32.184 0 00-2.85-15.76v-.01c-.2-.4-.32-.62-.32-.62s2.91-2.5 7.06 0a2.498 2.498 0 01.69.6c2.5 2.95 3.18 12.87 3.06 19.96zM159.03 180.736c-.04 1.12-.1 2.29-.17 3.48v.01c-.01.01-.03.01-.04.02a54.605 54.605 0 01-6.99 2.77c-.01 0-.02.01-.03.01l-.11-2.81-.01-.37-.53-13.68a2.295 2.295 0 012.29-2.4h5.07c.25 0 .43.51.55 1.43v.01a86.661 86.661 0 01-.03 11.53z"
        fill="#2f2e41"
      />
      <path
        data-name="Path 975"
        d="M122.974 114.764v15.8h3.658l4.655-4.988-.623 4.988h16.17l-1-4.988 2 4.988h2.578v-15.8z"
        fill="#2f2e41"
      />
      <ellipse
        data-name="Ellipse 191"
        cx={120.48}
        cy={131.184}
        rx={1.247}
        ry={2.286}
        fill="#a0616a"
      />
      <ellipse
        data-name="Ellipse 192"
        cx={152.489}
        cy={131.184}
        rx={1.247}
        ry={2.286}
        fill="#a0616a"
      />
      <path
        d="M186.52 117.606a55.71 55.71 0 10-96.27 52.92c.5.63 1.02 1.26 1.55 1.87.04.04.07.08.11.12a54.137 54.137 0 004.15 4.28 40 40 0 001.18 1.05c.76.68 1.54 1.33 2.35 1.95a54.775 54.775 0 004.94 3.47l.06.03a56.8 56.8 0 003.39 1.94l.47.24c1.08.56 2.18 1.09 3.31 1.58.23.1.48.2.71.3.02.01.03.01.05.02a54.115 54.115 0 007.02 2.37 55.732 55.732 0 0014.37 1.88c1.77 0 3.52-.08 5.25-.25a55.508 55.508 0 0011.64-2.35c.01 0 .02-.01.03-.01a54.605 54.605 0 006.99-2.77c.01-.01.03-.01.04-.02.51-.24 1-.5 1.5-.75l.24-.12c.4-.21.8-.42 1.2-.64 1-.55 1.98-1.13 2.95-1.75.27-.16.53-.33.8-.51.88-.58 1.75-1.2 2.59-1.83.15-.11.31-.22.46-.34a55.692 55.692 0 0018.92-62.68zm-20.76 60.25c-.33.26-.67.51-1.01.74-.33.25-.66.49-1 .71-.29.21-.58.41-.88.6-.25.17-.5.33-.75.49q-1.245.78-2.52 1.5l-.27.15c-.38.21-.76.41-1.14.61-.05.03-.11.05-.16.08l-.06.03c-.48.25-.97.49-1.46.72a49.028 49.028 0 01-5.83 2.36c-.26.09-.52.18-.78.26a52.457 52.457 0 01-11.02 2.24c-1.64.15-3.3.23-4.97.23a51.952 51.952 0 01-13.59-1.78 49.391 49.391 0 01-6.67-2.26c-.23-.09-.46-.19-.68-.28-.12-.05-.23-.1-.35-.16-.94-.41-1.87-.86-2.78-1.33l-.44-.23c-1.11-.58-2.2-1.2-3.26-1.86-.39-.24-.78-.49-1.16-.75a53.71 53.71 0 01-3.51-2.53c-.77-.58-1.51-1.2-2.23-1.84-.38-.32-.75-.67-1.11-1a50.883 50.883 0 01-4.02-4.16c-.42-.48-.83-.96-1.22-1.46a3.8 3.8 0 01-.25-.31 52.66 52.66 0 1173.12 9.23z"
        fill="#cbcbcb"
      />
      <path
        d="M529.244 201.146a11.628 11.628 0 011.391 1.19l53.754-10.602 4.319-12.59 19.853 4.034-5.48 23.032a8.838 8.838 0 01-8.156 6.781l-62.255 3.12a11.597 11.597 0 11-3.426-14.965z"
        fill="#ffb7b7"
      />
      <path
        d="M583.08 189.244a4.966 4.966 0 01-.359-4.124l8.39-23.499a13.807 13.807 0 0127.103 5.288L617 191.913a4.972 4.972 0 01-5.932 4.635l-24.63-4.881a4.966 4.966 0 01-3.357-2.423z"
        fill="#6c63ff"
      />
      <path
        fill="#ffb7b7"
        d="M569.599 494.016l-15.804-.001-7.518-60.956 23.324.001-.002 60.956z"
      />
      <path
        d="M573.629 509.335l-50.957-.002v-.645a19.835 19.835 0 0119.834-19.833h.001l31.123.001z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb7b7"
        d="M719.25 477.453l-14.636 5.959-29.949-53.621 21.603-8.794 22.982 56.456z"
      />
      <path
        d="M728.76 490.121l-47.196 19.214-.243-.597a19.835 19.835 0 0110.89-25.849h.002l28.825-11.735zM583.977 240.901l-7.271-38.472a39.042 39.042 0 016.008-33.428l26.582-35.54 25.39.746a144.965 144.965 0 016.297 91.766l-4.667 17.954c20.93 18.878 34.963 71.809 47.737 130.957l35.016 77.442-60.243 18.29-39.031-113.573-34.867 114.417-51.054-1.959 38.831-176.981z"
        fill="#2f2e41"
      />
      <circle cx={624.43} cy={101.509} r={24.768} fill="#ffb7b7" />
      <path
        d="M722.196 157.765c-4.601-8.989-6.172-19.195-9.713-28.651-3.542-9.457-9.977-18.84-19.732-21.445-5.534-1.479-11.414-.576-17.099-1.28-4.998-.619-10.378-3.17-12.292-7.61a31.348 31.348 0 00-1.613-13.005c-3.566-10.13-12.068-18.128-21.98-22.262-8.053-3.359-17.325-4.307-25.548-1.39s-15.124 10.043-16.602 18.642c-1.479 8.598 3.261 18.199 11.433 21.253 5.868 2.193 13.424 1.463 17.255 6.419 3.881 5.02 1.1 12.336-2.426 17.613l.241.245c11.514.135 23.96-2.09 31.89-10.438a25.267 25.267 0 002.369-2.915c.243.39.497.77.771 1.133 3.993 5.302 10.245 8.31 16.201 11.241 5.957 2.931 12.134 6.22 15.711 11.812 6.465 10.105 2.221 23.715 5.918 35.128a25.95 25.95 0 0048.803 1.582c-10.063.84-18.985-7.084-23.587-16.072z"
        fill="#2f2e41"
      />
      <path
        d="M482.164 221.145l1.048-25.45 35.947-71.47a10.368 10.368 0 014.703-4.677 10.49 10.49 0 018.105-.529 10.486 10.486 0 016.54 6.628 10.847 10.847 0 01.455 2.035 10.386 10.386 0 01-1.043 5.979l-35.995 71.566z"
        fill="#6c63ff"
      />
      <path
        d="M528.339 118.947l-39.02 77.58-2.975-1.496 37.74-75.034a9.945 9.945 0 014.255-1.05zM538.465 127.746l-37.532 74.623-2.975-1.497 38.939-77.419a9.998 9.998 0 011.135 2.35 10.328 10.328 0 01.433 1.943zM534.729 121.087l-39.45 78.437-2.976-1.496 39.5-78.536a9.688 9.688 0 011.23.517 10.198 10.198 0 011.696 1.078zM486.677 216.87l-3.97 3.196.333-8.429c1.363.473 2.839 2.525 3.637 5.232z"
        fill="#3f3d56"
      />
      <path
        d="M527.608 136.747a11.63 11.63 0 01.264 1.812l47.459 27.378 11.535-6.64 12.298 16.099-19.279 13.74a8.838 8.838 0 01-10.604-.258l-48.938-38.607a11.597 11.597 0 117.265-13.524z"
        fill="#ffb7b7"
      />
      <path
        d="M575.983 163.2a4.966 4.966 0 012.443-3.341l21.778-12.178a13.807 13.807 0 0116.933 21.813l-17.364 18.031a4.972 4.972 0 01-7.517-.411l-15.338-19.88a4.966 4.966 0 01-.935-4.033z"
        fill="#6c63ff"
      />
      <path d="M742.628 510h-381a1 1 0 010-2h381a1 1 0 010 2z" fill="#cbcbcb" />
    </svg>
  );
}

export default SvgWriteWall;
