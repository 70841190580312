/* eslint-disable react/prop-types */
import React, { createContext, useReducer } from 'react';
import projectReducer from './reducers/projectReducer';
import authReducer from './reducers/authReducers';
import initialState from './states/projectState';
import authIntialState from './states/authState';

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(projectReducer, initialState);
  const [authState, authDispatch] = useReducer(authReducer, authIntialState);

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Context.Provider value={[
      state, dispatch, authState, authDispatch,
    ]}
    >
      {children}
    </Context.Provider>
  );
};

export const Context = createContext([]);

export default Store;
