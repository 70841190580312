const projectActions = {
  ADD_PROJECT: 'ADD_PROJECT',
  EDIT_KEY: 'EDIT_KEY',
  IS_EDITING: 'IS_EDITING',
  NOT_EDITING: 'NOT_EDITING',
  DELETE_KEY: 'DELETE_KEY',
  ADD_KEY: 'ADD_KEY',
  ALL_PROJECTS: 'ALL_PROJECTS',
  GET_PROJECT_ID: 'GET_PROJECT_ID',
  JSON_TRUE: 'JSON_TRUE',
  GRAPH_DATA: 'GRAPH_DATA',
  PROJECT_DATA: 'PROJECT_DATA',
  EMBED: 'EMBED',
  RESET_KEY: 'RESET_KEY',
};

export default projectActions;
