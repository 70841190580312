/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext } from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { FaUserPlus } from 'react-icons/fa';
import Flash from '../utils/Flash';
import { Context } from '../../store/store';
import authActions from '../../store/actions/authActions';
import TextError from './TextError';
import Btnloader from '../loading/Btnloader';
import './Signup.scss';

const Signup = () => {
  const [loader, setLoader] = useState(false);
  const [messenger, setMessenger] = useState(false);
  const [, , authState, authDispatch] = useContext(Context);
  const { message } = authState;
  const { MODAL } = authActions;
  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
  };
  const onSubmit = (values, { resetForm }) => {
    setLoader(true);
    setMessenger(false);
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BACKEND_URL}/users/signup`,
      data: values,
    })
      .then(res => {
        if (res) {
          console.log('SignUp response: ', res);
          setLoader(false);
          setMessenger(true);
          authDispatch({ type: MODAL, payload: res.data.msg });
          //  An email has been sent to this account, please verify your account via this email
        }
      })
      .catch(err => {
        console.log('Error occured: ', err);
        setMessenger(true);
        authDispatch({ type: MODAL, payload: 'Error Occured, try again' });
        setLoader(false);
      });
    resetForm({});
  };
  const validationSchema = Yup.object({
    firstname: Yup.string().required('Firstname is required'),
    lastname: Yup.string().required('Lastname is required'),
    email: Yup.string().email('Invalid Email format').required('Email is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  return (
    <>
      {messenger && (
        <Flash>
          {message}
        </Flash>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form className="sign-form">
          <div className="sign-icon">
            <FaUserPlus size="5rem" color="white" />
          </div>
          <h1>Sign Up</h1>
          <div className="form-control">
            <label htmlFor="firstname">First Name</label>
            <Field type="text" id="firstname" name="firstname" placeholder="First name" autoComplete="new-off" />
            <ErrorMessage name="firstname" component={TextError} />
          </div>
          <div className="form-control">
            <label htmlFor="lastname">Last Name</label>
            <Field type="text" id="lastname" name="lastname" placeholder="Last name" autoComplete="new-off" />
            <ErrorMessage name="lastname" component={TextError} />
          </div>
          <div className="form-control">
            <label htmlFor="email">Email</label>
            <Field type="email" id="email" name="email" placeholder="johndoe@mail.com" autoComplete="new-off" />
            <ErrorMessage name="email" component={TextError} />
          </div>
          <div className="form-control">
            <label htmlFor="password">Password</label>
            <Field type="password" id="password" name="password" placeholder="Password" autoComplete="new-off" />
            <ErrorMessage name="password" component={TextError} />
          </div>
          <div className="form-control">
            <label htmlFor="confirm-password">Confirm Password</label>
            <Field
              type="password"
              id="confirm-password"
              name="confirmPassword"
              placeholder="Confirm Password"
              autoComplete="new-off"
            />
            <ErrorMessage name="confirmPassword" component={TextError} />
          </div>
          <div className="redirect-signin">
            Already have an account?
            <Link to="/signin"> Sign in</Link>
          </div>
          <Button type="submit" className="btn">{loader ? <Btnloader /> : 'Sign Up' }</Button>
        </Form>
      </Formik>
    </>
  );
};

export default Signup;
