/* eslint-disable no-case-declarations */
import projectActions from '../actions/projectActions';

const {
  ADD_KEY,
  EDIT_KEY,
  IS_EDITING,
  NOT_EDITING,
  DELETE_KEY,
  ADD_PROJECT,
  EMBED,
  ALL_PROJECTS,
  GET_PROJECT_ID,
  JSON_TRUE, GRAPH_DATA, PROJECT_DATA,
  RESET_KEY,
} = projectActions;

const projectReducer = (state, action) => {
  switch (action.type) {
    case ADD_KEY:
      return {
        ...state, key: [...state.key, action.payload],
      };
    case RESET_KEY:
      return {
        ...state, key: action.payload,
      };
    case ALL_PROJECTS:
      return {
        ...state, projects: action.payload,
      };
    case EDIT_KEY:
      const editedList = state.key.map((keyItem) => {
        if (keyItem.id === state.editId) {
          return { ...keyItem, keyName: action.payload };
        }
        return keyItem;
      });
      return {
        ...state, key: editedList,
      };
    case NOT_EDITING:
      return {
        ...state, isEditing: false, editId: null,
      };
    case IS_EDITING:
      return {
        ...state, editId: action.payload, isEditing: true,
      };
    case DELETE_KEY:
      return {
        ...state, key: state.key.filter((item) => action.payload !== item.id),
      };
    case ADD_PROJECT:
      return {
        ...state, project: { ...state.project, [action.payload]: action.value },
      };
    case GET_PROJECT_ID:
      return {
        ...state, currentProjectId: action.payload,
      };
    case JSON_TRUE:
      return {
        ...state, redirect: action.payload,
      };
    case GRAPH_DATA:
      return {
        ...state, graphData: action.payload,
      };
    case PROJECT_DATA:
      return {
        ...state, projectData: action.payload,
      };
    case EMBED:
      return {
        ...state, isMount: action.payload,
      };
    default:
      throw new Error('Error type mis-match');
  }
};

export default projectReducer;
