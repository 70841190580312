import React from 'react';
import './index.scss';

const index = () => (
  <div>
    <h2 className="loader"> </h2>
  </div>
);

export default index;
