/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Swal from 'sweetalert2';
import logo from './avatar-beta.png';
import { Context } from '../../store/store';
import projectActions from '../../store/actions/projectActions';
import Loader from '../loading';
import './index.scss';

const useStyles = makeStyles({
  root: {
    minWidth: 260,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 15,
  },
});

const index = () => {
  const classes = useStyles();
  const [state, dispatch] = useContext(Context);
  const [searchTerm, setSearchTerm] = useState('');
  const [found, setFound] = useState(false);
  const { ALL_PROJECTS } = projectActions;
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(async () => {
    console.log('About to get all projects');

    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/project`,
      params: {
        // eslint-disable-next-line no-underscore-dangle
        user: user._id,
      },
    });
    const { projects } = await response.data;
    if (response.data) {
      dispatch({ type: ALL_PROJECTS, payload: projects });
      setFound(true);
    }
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const customDate = (date) => {
    const myDate = new Date(date);
    return myDate.toDateString();
  };
  const customTime = (date) => {
    const myTime = new Date(date);
    return myTime.toLocaleString().split(',')[1];
  };

  const handleDelete = async (projectId, fileType) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/project`, { data: { projectId, fileType } });
        if (response.status === 200) {
          let { projects } = state;
          projects = projects.filter(project => project._id !== projectId);
          dispatch({
            type: ALL_PROJECTS,
            payload: projects,
          });
          Swal.fire(
            'Deleted!',
            'Project has been deleted.',
            'success',
          );
        }
      }
    });
  };
  const { projects } = state;
  return (
    <div className="wrapper">
      <div className="metrics">
        <div className="profile">
          <img style={{ width: '50%' }} src={logo} alt="User Avatar" className="user" title={user.firstname} />
        </div>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Number of Projects:
            </Typography>
            <Typography variant="h5" component="h2" align="center">
              {projects.length}
            </Typography>
            <TextField
              fullWidth
              style={{ marginTop: '1%' }}
              label="Search"
              variant="outlined"
              size="big"
              InputProps={{
                placeholder: 'Search Projects By Name',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
          </CardContent>
        </Card>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography className={classes.title} color="textSecondary">
              Name
            </Typography>
            <Typography variant="h5" component="h2">
              {`${user.firstname} ${user.lastname}`}
            </Typography>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Email Address
            </Typography>
            <Typography variant="h5" component="h2">
              {`${user.email}`}
            </Typography>
          </CardContent>
        </Card>
      </div>
      {projects.length === 0
        ? (
          <>
            {!found
              ? (
                <>
                  <Loader />
                  <h2 style={{ fontWeight: 300 }}>Your Project appears here</h2>
                </>
              )
              : <h2 style={{ fontWeight: 300 }}>No Project Found, Please create a project</h2>}
          </>
        )
        : (
          <div className="projects">
            {projects.sort((a, b) => {
              const dateA = new Date(a.date); const dateB = new Date(b.date);
              return dateB - dateA;
            }).filter((project) => {
              if (searchTerm === '') {
                return project;
              }
              if (project.projectTitle.toLowerCase().includes(searchTerm.toLowerCase())) {
                return project;
              }
              return null;
            }).map((project) => (
              <div key={project._id} className="project">
                <Link to={`/project/${project._id}`}>
                  <div>
                    <h2>{project.projectTitle}</h2>
                    <p>{project.subtitle}</p>
                    <p>
                      FileType:
                      {' '}
                      {project.fileType}
                    </p>
                  </div>
                  <div>
                    <p>
                      Created by:
                      {' '}
                      {user.firstname}
                    </p>
                    <p>
                      Created at:
                      {' '}
                      {customDate(project.date)}
                    </p>
                    <p>
                      Time:
                      {' '}
                      {customTime(project.date)}
                    </p>
                  </div>
                </Link>
                <Button
                  variant="outlined"
                  value={project._id}
                  style={{ float: 'right', margin: '1%' }}
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleDelete(project._id, project.fileType)}
                  size="medium"
                >
                  Delete
                </Button>
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

export default index;
